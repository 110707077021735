import {useParams} from "react-router-dom";
import {useEffect, useRef} from "react";
import {LocalizedStrings} from "./LocalizedStrings";

export function MultiProgressBar(): JSX.Element {
  let {width, height, minValue, maxValue, valueZone, valueTower, valueRainmaker, valueClam} = useParams()
  let localizedString = LocalizedStrings.strings('zh')
  const prop: ProgressBarParams = {
    name_zone: localizedString.zone,
    name_tower: localizedString.tower,
    name_rainmaker: localizedString.rainmaker,
    name_clam: localizedString.clam,
    width: +(width ?? "0"),
    height: +(height ?? "0"),
    minValue: +(minValue ?? "0"),
    maxValue: +(maxValue ?? "0"),
    value_zone: valueZone === 'x' ? undefined : +(valueZone ?? "0"),
    value_tower: valueTower === 'x' ? undefined : +(valueTower ?? "0"),
    value_rainmaker: valueRainmaker === 'x' ? undefined : +(valueRainmaker ?? "0"),
    value_clam: valueClam === 'x' ? undefined : +(valueClam ?? "0")
  }
  return <div>
    <BarView name_zone={prop.name_zone}
             name_tower={prop.name_tower}
             name_rainmaker={prop.name_rainmaker}
             name_clam={prop.name_clam}
             width={prop.width}
             height={prop.height}
             minValue={prop.minValue}
             maxValue={prop.maxValue}
             value_zone={prop.value_zone}
             value_tower={prop.value_tower}
             value_rainmaker={prop.value_rainmaker}
             value_clam={prop.value_clam} />
  </div>
}

interface ProgressBarParams {
  name_zone: string
  name_tower: string
  name_rainmaker: string
  name_clam: string
  width: number
  height: number
  minValue: number
  maxValue: number
  value_zone: number | undefined
  value_tower: number | undefined
  value_rainmaker: number | undefined
  value_clam: number | undefined
}


function BarView(props: ProgressBarParams): JSX.Element {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasPaddingHorizontal: number = props.height / 2
  const canvasPaddingVertical: number = props.height

  useEffect(() => {
    const slope = 1.4 / 1.6

    const canvas = canvasRef.current;
    if(canvas == null) throw new Error('Could not get canvas');
    const ctx = canvas.getContext('2d');
    if (ctx == null) throw new Error('Could not get context');

    const darkColor: string = `rgb(231 101 42)`
    const lightColor: string = `rgb(239 143 53)`
    const filledColor: string = `rgb(67 64 33)`
    const backgroundColor: string = `rgb(11 0 0)`
    const textColor: string = `rgb(200 200 200)`
    const textHighlightedColor: string = `rgb(240 240 240)`

    const paddingX = Math.min(20, props.width / 24)
    const barWidth = (props.width - 4 * paddingX) / 2
    const barHeight = props.height * 2 / 14
    const barPaddingAbove = props.height * 4 / 14
    const stripeWidth = barHeight * slope

    ctx.fillStyle = backgroundColor
    ctx.fillRect(0, 0, props.width, props.height)

    function drawMode(name: string, startX: number, startY: number, value: number | undefined) {
      if (ctx == null) throw new Error('Could not get context')
      let coloredWidth = barWidth * ((value ?? props.minValue) - props.minValue) / (props.maxValue - props.minValue)
      coloredWidth = Math.max(coloredWidth, 0)
      coloredWidth = Math.min(coloredWidth, barWidth)

      const barStartX = startX + paddingX;
      const barStartY = startY + barPaddingAbove;
      const barEndX: number = startX + paddingX + barWidth

      // background
      ctx.fillStyle = lightColor
      ctx.fillRect(barStartX, barStartY, barWidth, barHeight)

      function drawParallelogram(x: number): void {
        if (ctx == null) throw new Error('Could not get context')
        ctx.beginPath()
        ctx.moveTo(barStartX + x, barStartY)
        if(x + stripeWidth <= coloredWidth) {
          ctx.lineTo(barStartX + x + stripeWidth, barStartY + barHeight)
        } else {
          ctx.lineTo(barStartX + coloredWidth, barStartY + (coloredWidth - x) / slope)
          ctx.lineTo(barStartX + coloredWidth, barStartY + barHeight)
        }
        ctx.lineTo(barStartX + x, barStartY + barHeight)
        if (x >= stripeWidth) {
          ctx.lineTo(barStartX + x - stripeWidth, barStartY)
        } else {
          ctx.lineTo(barStartX, barStartY + barHeight - x / slope)
          ctx.lineTo(barStartX, barStartY)
        }
        ctx.lineTo(barStartX + x, barStartY)
        ctx.fillStyle = darkColor
        ctx.fill()
        ctx.closePath()
      }

      for(let x = stripeWidth / 2; x <= coloredWidth; x += stripeWidth * 2) {
        drawParallelogram(x)
      }

      // mode name
      ctx.fillStyle = textHighlightedColor
      ctx.textAlign = "center"
      ctx.font = `${Math.round(barPaddingAbove / 2)}px Arial`
      // ctx.font = "30px Arial"
      ctx.fillText(name, barStartX + barWidth / 2, startY + barPaddingAbove * 0.55)

      // start & end score
      ctx.fillStyle = textColor
      ctx.font = `${Math.round(barPaddingAbove / 3)}px Arial`
      ctx.textAlign = 'left'
      ctx.fillText(`${Math.round(props.minValue)}`, barStartX, startY + barPaddingAbove - 5)
      ctx.textAlign = 'right'
      ctx.fillText(`${Math.round(props.maxValue)}`, barEndX, startY + barPaddingAbove - 5)

      // uncolored area
      if (value !== undefined) {
        ctx.fillStyle = filledColor
        ctx.fillRect(barStartX + coloredWidth, barStartY, barWidth - coloredWidth, barHeight)
      } else {
        // for counting
        ctx.fillStyle = lightColor
        ctx.fillRect(barStartX, barStartY, barWidth, barHeight)
      }

      // current score
      let localizedString = LocalizedStrings.strings('zh')
      let currentScore = value ? `${Math.round(value)}` : localizedString.counting
      let fontSize = Math.round(barPaddingAbove / 2)

      ctx.fillStyle = textHighlightedColor
      ctx.font = `bold ${fontSize}px Arial`
      ctx.textAlign = 'left'
      ctx.fillText(currentScore, barStartX + 2, barStartY + barHeight - 3)
    }

    drawMode(props.name_zone, 0, 0, props.value_zone)
    drawMode(props.name_tower, props.width / 2, 0, props.value_tower)
    drawMode(props.name_rainmaker, 0, props.height / 2, props.value_rainmaker)
    drawMode(props.name_clam, props.width / 2, props.height / 2, props.value_clam)

  });

  return <canvas ref={canvasRef} width={props.width + canvasPaddingHorizontal * 2} height={props.height + canvasPaddingVertical * 4 / 3} />;
}
