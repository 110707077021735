import React from 'react';
import {Routes, Route, BrowserRouter} from 'react-router-dom';
import {SplatProgressBar} from "./ProgressBar";
import {MultiProgressBar} from "./MultiProgressBar";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/single/:width/:height/:minValue/:maxValue/:value" element={<SplatProgressBar />}/>
        <Route path="/all/:width/:height/:minValue/:maxValue/:valueZone/:valueTower/:valueRainmaker/:valueClam" element={<MultiProgressBar />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
