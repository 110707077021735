import {useParams} from "react-router-dom";
import {useEffect, useRef} from "react";

export function SplatProgressBar(): JSX.Element {
  let {width, height, minValue, maxValue, value} = useParams()
  const prop: ProgressBarParams = {
    name: "test",
    width: +(width ?? "0"),
    height: +(height ?? "0"),
    minValue: +(minValue ?? "0"),
    maxValue: +(maxValue ?? "0"),
    value: +(value ?? "0")
  }
  return <div>
    <BarView name={prop.name} width={prop.width} height={prop.height} minValue={prop.minValue} maxValue={prop.maxValue} value={prop.value} />
  </div>
}

interface ProgressBarParams {
  name: string
  width: number
  height: number
  minValue: number
  maxValue: number
  value: number
}

function BarView(props: ProgressBarParams): JSX.Element {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasPaddingHorizontal: number = props.height / 2
  const canvasPaddingVertical: number = props.height

  useEffect(() => {
    const slope = 1.4 / 1.6

    const canvas = canvasRef.current;
    if(canvas == null) throw new Error('Could not get canvas');
    const ctx = canvas.getContext('2d');
    if (ctx == null) throw new Error('Could not get context');

    const darkColor: string = `rgb(231 101 42)`
    const lightColor: string = `rgb(239 143 53)`
    const filledColor: string = `rgb(67 64 33)`
    const backgroundColor: string = `rgb(11 0 0)`
    const textColor: string = `rgb(200 200 200)`

    let coloredWidth = props.width * (props.value - props.minValue) / (props.maxValue - props.minValue)
    coloredWidth = Math.max(coloredWidth, 0)
    coloredWidth = Math.min(coloredWidth, props.width)

    const stripeWidth = props.height * slope
    const startX = canvasPaddingHorizontal
    const endX: number = canvasPaddingHorizontal + props.width
    const startY = canvasPaddingVertical

    function drawParallelogram(x: number): void {
      if (ctx == null) throw new Error('Could not get context')
      ctx.moveTo(startX + x, startY)
      if(x + stripeWidth <= props.width) {
        ctx.lineTo(startX + x + stripeWidth, startY + props.height)
      } else {
        ctx.lineTo(startX + props.width, startY + (props.width - x) / slope)
        ctx.lineTo(startX + props.width, startY + props.height)
      }
      ctx.lineTo(startX + x, startY + props.height)
      if (x >= stripeWidth) {
        ctx.lineTo(startX + x - stripeWidth, startY)
      } else {
        ctx.lineTo(startX, startY + props.height - x / slope)
        ctx.lineTo(startX, startY)
      }
      ctx.lineTo(startX + x, startY)
      ctx.fillStyle = darkColor
      ctx.fill()
    }

    ctx.fillStyle = backgroundColor
    ctx.fillRect(0, 0, 2 * canvasPaddingHorizontal + props.width, props.height + canvasPaddingVertical * 4 / 3)

    ctx.fillStyle = lightColor
    ctx.fillRect(startX, startY, endX - startX, props.height)

    for(let x = startX + stripeWidth / 2; x <= endX - startX; x += stripeWidth * 2) {
      drawParallelogram(x)
    }

    ctx.fillStyle = filledColor
    ctx.fillRect(startX + coloredWidth, startY, endX - startX - coloredWidth, props.height)

    ctx.fillStyle = textColor
    ctx.textAlign = 'center'
    ctx.font = `${Math.round(startY / 2)}px Arial`
    // ctx.font = "30px Arial"
    ctx.fillText(props.name, startX + props.width / 2, startY * 2 / 4)

    ctx.font = `${Math.round(startY / 3)}px Arial`
    ctx.fillText(`${Math.round(props.minValue)}`, startX, startY - 5)
    ctx.fillText(`${Math.round(props.maxValue)}`, endX, startY - 5)

    ctx.fillStyle = lightColor
    ctx.font = `bold ${Math.round(startY / 2)}px Arial`
    ctx.fillText(`${Math.round(props.value)}`, startX + coloredWidth + 1, startY - 4)
    ctx.font = `${Math.round(startY / 2)}px Arial`
    ctx.fillStyle = darkColor
    ctx.fillText(`${Math.round(props.value)}`, startX + coloredWidth, startY - 5)

  });

  return <canvas ref={canvasRef} width={props.width + canvasPaddingHorizontal * 2} height={props.height + canvasPaddingVertical * 4 / 3} />;
}
