export type Lang = 'en' | 'zh'
export interface StringDict {
  zone: string,
  tower: string,
  rainmaker: string,
  clam: string,
  counting: string
}
export class LocalizedStrings {
  static strings(lang: Lang): StringDict {
    return this.stringDicts[lang]
  }

  static stringDicts: Record<Lang, StringDict> = {
    'en': {
      zone: "Zone",
      tower: "Tower",
      rainmaker: "Rain Maker",
      clam: "Clam",
      counting: "Counting…"
    },
    'zh': {
      zone: "区域",
      tower: "塔楼",
      rainmaker: "鱼",
      clam: "蛤蜊",
      counting: "计测中……"
    }
  }
}
